@media only screen and (max-width: 600px) {
    .mainContainer {
        display: flex;
        margin-top: 14vh;
        flex-direction: column;
        align-items: center;
    }

    .mainContainer img {
        width: 66%;
    }

    .mainContainer h2 {
        color: #e32228;
        font-size: 40px;
        font-weight: 300;
    }

    .mainContainer h3 {
        color: #000000;
        font-size: 30px;
    }

    .loginFormContainer {
        margin-top: 16px;
        border-radius: 10px;
        width: 90%;
        margin: 40px auto 0 auto;
        padding: 2% 3%;
        padding-bottom: 5%;
    }

    .WelcomeContainer{
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        align-items: center;
    }
    .WelcomeContainer h1{
        font-weight: bold;
    }

    .WelcomeContainer p{
        font-weight:normal;
    }

    .form-group {
        margin-top: 4vh;
        border-radius: 8px;
    }

    .loginSubmit {
        background-color: #6558f5;
        border: none;
        border-radius: 8px;
        color: #fff;
        font-size: 20px;
        height: 48px;
        text-align: center;
        width: 94%;
        margin-top: 4vh;
    }

}