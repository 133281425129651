.navbar {
  background: #495057;
  padding: p2x 20px;
  overflow-y: visible;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 12;
}

.headerUl {
  /* height: 48px; */
}

.navbar-nav li {
  padding: 0px 16px;
}

.navbar-nav li a {
  /* float: right; */
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.17;
  color: #fff !important;
  /* margin-top: 11px; */
  padding: 0px !important;
  cursor: pointer;
}

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
  color: #fff !important;
}

.navbar-nav li a:hover {
  color: #678987 !important;
}

.navbar-nav>li:last-child {
  border-right:  1px solid rgba(255, 255, 255, .3);
  padding-right: 12px;
  display: flex;
  align-items: center;
}


.caret {
  border-width: 7px;
  border-style: solid;
  border-color: #fff transparent transparent;
  right: -1px;
  bottom: 18px;
  position: absolute;
}

.dropbtn {
  display: inline-block;
  text-align: center;
  padding: 0px 4px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  color: #fff !important;
  cursor: pointer
}

.headerDropdown {
  padding-left: 1vw;
  display: flex;
  align-items: center;
}

.headerDropdown img {
  width: 2vw;
  height: 2vw;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 200px;
  border-radius: 12px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  z-index: 1;
}

.dropdown-content a {
  font-size: 18px;
  color: #707070;
  padding: 16px 36px;
  background-color: #fff;
  border-radius: 12px;
  text-decoration: none;
  display: block;
  transition: all 0.3s ease-in-out 0s;
}

.dropdown-content a:hover {
  /* color: #535c69; */
  border-radius: 12px;
  font-weight: bold;
  /* background-color: #f7f7f7; */
}

.headerDropdown:hover .dropdown-content {
  display: block;
}

.headerInput {
  width: 254px;
  border-radius: 28px;
  box-sizing: border-box;
  font-size: 14px;
  background: url('../assets/search.svg') 95% / 20px no-repeat;
  padding: 4px 20px;
  -webkit-transition: width 0.6s ease-in-out;
  transition: width 0.6s ease-in-out;
  color: black;
  font-weight: bold;
  background-color: #868d95;
  border: none;
  box-shadow: none;
  outline: none;
  max-width: 26.667vw;
  margin-left: 80px;
}

.headerInput::placeholder {
  font-size: 15px;
  color: #707070;
}

.headerInput:focus {
  width: 100%;
  border: solid 1px #707070;
  background-color: #fff;
}

.zrmText{
  font-size: 20px;
  color: #e6e9ed;
  margin-bottom: 0;
  padding-left: 5px;
  font-weight: bold;
}
@media only screen and (max-width: 600px) {
 

}

.sidePanelComponent {
  right: -3000px;
  border-left: 1px solid #e6e9ed;
  background: #f2f7f7;
  width: 300px;
  z-index: 1;
  position: fixed;
  left: auto;
  top: 0;
  height: 100vh;
  transition: right .6s ease-out;
}

.closeUserInfo {
  float: right;
  margin-top: 20px;
  margin-right: 20px;
  /* right:10px */
}

.circle {
  float: right;
  margin-top: 6px;
  width: 12px;
  height: 12px;
  background-color: #62bd19;
  border-radius: 50%;
}

.online {
  float: right;
  margin-right: 5px
}

.logoutDiv {
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  padding: 14px 0;
  position: absolute;
  bottom: 2px;
  width: 100%
}

.logoutBtn {
  margin-left: 10px;
  font-size: 18px;
  color: #343434 !important;
  /* position:relative; */
}

.logoutBtn:hover {
  text-decoration: none;
}

.pulseDropdown .btn-secondary {
  background-color: transparent !important;
  /* border-color : none !important; */
  border: none !important;
  box-shadow: none !important
}

.pulseNav {
  padding: 0px !important;
  /* margin-top: 7px !important; */
  /* text-align: left !important;
  font-size: 18px !important;
  font-weight: bold !important;
  line-height: 1.17 !important; */
}

.pulseNav .dropdown-toggle {
  /* text-align: left !important; */
  font-size: 14px !important;
  font-weight: bold !important;
  line-height: 1.4 !important;
  color: #fff;

}

.pulseDropdownMenu {
  text-align: inherit!important;
  background-color: #535c69 !important;
  min-width: 7rem!important;
}

.pulseDropdownMenu .dropItem {
  /* text-align: left!important; */
  padding: 5px 10px !important;
  font-size: 10px !important;
  padding-right: 24px !important;
  /* color: #fff !important; */
}

.pulseDropdownMenu .dropdown-item {
  width: 100% !important
}

.dropItem:hover{
  background-color: transparent;
}
.dropItem a {
  font-size: 12px!important;
  text-decoration: none!important;
  /* color : #000 !important */
}


@media  only screen and (max-width:600px){

 
  .headerInput{
    max-width: 100%;
    margin-top: 8px;
    padding: 6px;
    border: 1px solid #ccc;
    margin: 0;
    float: none;
    display: block;
    text-align: left;

  }
  .navbar {
    padding: 5px 1rem;
  }
  .headerUl {
    height: auto;
  }
  .navbar-nav li {
    padding: 6px 4px !important;
  }
  .navItem{
    padding: 6px 16px !important;
    font-size: 14px;
    padding-left: 16px !important;
  }
  .headerDropdown{
    padding: 0.375rem 0.75rem !important;

  }
  .headerDropdown img{
    width: 5%;
    height: 5%;
  }
 
 
}