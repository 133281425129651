.left{
  background-image: url("../assets/bg-zrm.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100vh;
}
.left img{
  text-align: center;
  width: 13.802vw;/*265px;*/
  height: 17.969vw;/*345px;*/
}
.left h5{
  font-size: 1.250vw;/*24px;*/
  text-align: center;
  color: #ffffff;
  margin-bottom: 0px;
}

input:focus{
  outline: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
.rightForgot{
  background-color: #fff;
  padding: 10.156vw 13.281vw 0.833vw 10.938vw !important;/*195px 255px 16px 210px;*/
}
.rightForgot h1{
  font-size: 2.188vw;/*42px;*/
  font-weight: bold;
  line-height: 1.19;
  text-align: left;
  color: #1a1a1a;
  /* margin-bottom: 5.208vw; */ 
}
.rightForgot .form-group {
  margin-bottom: 2.083vw;/*40px;*/
  margin-top: 5.208vw;
}
.rightForgot .form-control {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0px;
  padding-bottom: 0.521vw;/*10px;*/
  padding-left: 0.365vw;/*7px;*/
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-bottom: 1px solid #707070;
  border-radius: 0;
  font-size: 1.250vw;/*24px;*/
  line-height: 1.21;
  text-align: left;
  color: #1a1a1a;
  transition: all 0.5s ease-in;
}
.rightForgot .form-group ::placeholder{
  font-size: 1.250vw;/*24px;*/
  line-height: 1.21;
  letter-spacing: normal;
  font-weight: 100;
  text-align: left;
  color: #707070;
}
.rightForgot .form-group input:focus{
  font-weight: bold;
  border-bottom: 1px solid #6AB883;
}
.rightForgot .forget-password{
  font-size: 0.938vw;/*18px;*/
  line-height: 1.17;
  float: rightForgot;
  width: fit-content;
  color: #b9b9b9;
  margin-top: -1.406vw;/*27px;*/
  margin-bottom: 0px;
  cursor: pointer;
}
.rightForgot .forget-password:hover{
  color: #1a1a1a;
}
.rightForgot .loginSubmit{
  width: 100%;
  height: 3.854vw;/*74px;*/
  border-radius: 1.927vw;/*37px;*/
  background-color: #f0bd44;
  font-size: 1.458vw;/*28px;*/
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  margin-top: 4.688vw;/*90px;*/
  border: none;
  outline: 0;
  transition: all 0.2s ease-in;
}
.rightForgot .loginSubmit:hover{
  background-color: #F09A44;
}
.rightForgot .register{
  text-align: center;
  margin-top: 2.604vw;/*50px;*/
}
.rightForgot .register h2{
  font-size: 1.250vw;/*24px;*/
  font-weight: bold;
  line-height: 1.21;
  color: #000000;
  margin-bottom: 0.833vw;/*16px;*/
}
.rightForgot .register p{
  font-size: 1.250vw;/*24px;*/
  line-height: 1.21;
  color: #6ab883;
  margin-bottom: 0px;
  text-decoration: underline #6ab883;
  cursor: pointer;
}
.rightForgot .register p:hover{
  color: #555CFF;
  text-decoration: underline #555CFF;
}

