.zunpulseOpsDashboardMainDiv .metricsSummary .order-card {
    color: #fff;
}

.zunpulseOpsDashboardMainDiv .metricsSummary .bg-c-blue {
    background: linear-gradient(45deg,#4099ff,#73b4ff);
}

.zunpulseOpsDashboardMainDiv .metricsSummary .bg-c-green {
    background: linear-gradient(45deg,#2ed8b6,#59e0c5);
}

.zunpulseOpsDashboardMainDiv .metricsSummary .bg-c-yellow {
    background: linear-gradient(45deg,#FFB64D,#ffcb80);
}

.zunpulseOpsDashboardMainDiv .metricsSummary .bg-c-pink {
    background: linear-gradient(45deg,#FF5370,#ff869a);
}

.zunpulseOpsDashboardMainDiv .metricsSummary .card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
    box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
    border: none;
    margin-bottom: 30px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    height: 100%;
}

.zunpulseOpsDashboardMainDiv .metricsSummary .card .card-block {
    padding: 25px;
}

.zunpulseOpsDashboardMainDiv .metricsSummary .order-card i {
    font-size: 26px;
}

.zunpulseOpsDashboardMainDiv .metricsSummary .f-left {
    float: left;
}

.zunpulseOpsDashboardMainDiv .metricsSummary .f-right {
    float: right;
}

.zunpulseOpsDashboardMainDiv .metricsSummary .summaryCards {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
}
.zunpulseOpsDashboardMainDiv .metricsSummary .summaryCards > [class*='col-'] {
    display: flex;
    flex-direction: column;
}