.followUpDetailView {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 20px;
    background-color: #f5f5f5;
}

.followUpDetailView .details-container {
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    box-sizing: border-box;
}

.followUpDetailView .details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.followUpDetailView .details-header h1 {
    font-size: 1.5em;
    margin: 0;
    color: #333;
}

.followUpDetailView .details-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.followUpDetailView .details-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
}

.followUpDetailView .details-item:last-child {
    border-bottom: none;
}

.followUpDetailView .details-item h2 {
    font-size: 1.2em;
    margin: 0;
    color: #333;
    width: 40%;
}

.followUpDetailView .details-item p {
    margin: 0;
    color: #666;
    width: 60%;
    text-align: right;
    word-wrap: break-word;
}


.followUpDetailView .comments-container {
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    box-sizing: border-box;
    height: 300px;
    overflow-y: auto;
}

.followUpDetailView .comments-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.followUpDetailView .comments-header h1 {
    font-size: 1.5em;
    margin: 0;
    color: #333;
}

.followUpDetailView .comments-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.followUpDetailView .comment-item {
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
}

.followUpDetailView .comment-item:last-child {
    border-bottom: none;
}

.followUpDetailView .comment-item p {
    margin: 0;
    color: #666;
}

.followUpDetailView .comment-item .timestamp {
    color: #999;
    font-size: 0.9em;
}

.nameCellFollowup, .nameCellFollowup:hover, .nameCellFollowup:focus, .nameCellFollowup:active {
    text-decoration: none;
    color: inherit;
}
