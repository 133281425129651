@media only screen and (min-width: 600px) {

.row {
	margin: 0px auto;
	padding: 0px !important;
}

.bottom-section {
	width: 100%;
	margin: 10px auto;
}

.customer-info {
	background-color: white;
	padding: 0px !important;
	margin-bottom: 10px;
}

.responsible {
	background-color: white;
	padding: 0px !important;
	border: 1px solid #ddd;
}

.customer-info {
	border: 1px solid #ddd;
}

.customer-info td:first-child, .responsible td:first-child {
	color: #bbb;
	font-size: 12px;
	width: 35%;
}

table {
	border-collapse: separate;
	width: 100%;
	margin: 0px auto;
}

.customer-info td, .customer-info th, .responsible td {
	padding: 7px 30px;
}

.responsible th {
	padding: 5px 20px;
}

.row table tr {
	border-bottom: 1px solid #efefef;
}

.customer-info table tr:hover, .responsible tr:not(:first-child):hover {
	background-color: #f3f3f3;
}

.customer-info tr:last-child {
	border-bottom: none;
}

.row table tr td {
	margin-left: 15px !important;
}

.topbar-section {
	width: 98%;
	padding: 10px 0px;
	margin: 10px auto;
	border: 1px solid #ddd;
	border-radius: 5px;
	background-color: white;
}

.topbar-section strong {
	font-size: 20px;
}

.topbar-left, .topbar-right {
	padding: 0px;
}

.topbar-right p {
	float: right;
	opacity: .7;
}

.topbar-right ul {
	margin: 0px;
}

.topbar-right ul li {
	float: left;
	list-style: none;
	margin: 0px 10px;
	text-decoration: none;
}

.topbar-right ul li a {
	color: grey;
	text-decoration: none;
}

.topbar-right ul li:last-child {
	margin: 0px 0px 0px 10px;
}

.topbar-right ul li .glyphicon {
	margin: 0px 5px;
	color: grey;
}

.status-green {
	color: #449D44;
}

.status-orange {
	color: #e08128;
}

.status-red {
	color: #ea3131;
}

.hide-status {
	display: none;
}

.progressbar {
	min-width: 600px;
	counter-reset: step;
}

.progressbar ul li {
	list-style-type: none;
	float: left;
	text-align: center;
	position: relative;
	width: 11.11%;
	color: grey;
	font-size: 12px;
	line-height: 14px;
	padding: 0px 3px;
	cursor: pointer;
}

.progressbar ul li.done {
	color: #bbb;
}

.progressbar ul li.active {
	color: #5fc0ea;
	font-weight: 700;
}

.progressbar ul li:before {
	content: counter(step);
	counter-increment: step;
	font-size: 22px;
	color: #bbb;
	font-weight: 700;
	width: 50px;
	height: 50px;
	line-height: 45px;
	border-radius: 50%;
	border: 3px solid #ccc;
	display: block;
	text-align: center;
	background-color: #efefef;
	margin: 5px auto;
}

.progressbar ul li.done:before {
	content: '\2713';
	color: white;
	border: 3px solid #80DBB0;
	background-color: #80DBB0;
}

.progressbar ul li.active:before {
	color: white;
	border: 3px solid #5fc0ea;
	background-color: #5fc0ea;
}

.progressbar ul li:after {
	content: '';
	position: absolute;
	width: 100%;
	height: 4px;
	background-color: #ccc;
	top: 29px;
	left: -50%;
	z-index: -1;
}

.progressbar ul li.done:after {
	background-color: #80DBB0;
}

.progressbar ul li.active:after {
	background-color: #80DBB0;
	border: none;
	border-top: 5px dotted #5fc0ea;
	background-color: rgba(0, 0, 0, 0);
}

.progressbar ul li:first-child:after {
	content: none;
}

.hide {
	display: none
}

.table-wrapper {
	display: flex;
	padding-left: 0px !important;
	padding-right: 0px
}

.scrap-variables {
	width: 100%;
	padding: 10px 0px;
	margin: 10px auto;
	border: 1px solid #ddd;
	/* border-radius: 5px; */
	background-color: white;
}

.button-edit {
	margin-left: 40px;
	/* float: right; */
	background-color: transparent;
	border: none;
	border-radius: 3px;
	padding: 6px 10px;
	background: #bbed21;
	color: #535c69;
	-webkit-transition: background 0.5s;
	transition: background 0.5s;
}

.button-edit:hover {
	background: #d7f57e;
}

.button-cancel {
	margin-right: 40px;
	float: right;
	background-color: transparent;
	border: none;
	border-radius: 3px;
	padding: 6px 10px;
	background: white;
	color: #535c69;
	-webkit-transition: background 0.5s;
	transition: background 0.5s;
}

.button-cancel:hover {
	background: orange;
	color: #fff;
}

.button-cancel:focus, .button-cancel:active {
	outline: none;
	border: none
}

.button-edit:focus, .button-edit:active {
	outline: none !important;
	box-shadow: none;
}

.topbarVariables {
	width: 60%;
	/* padding : 2% */
}

.titleInput {
	width: 30%;
	margin-top: 10px;
}

.refreshButton {
	cursor: pointer;
	padding-left: 13px;
}

.utmVariables p {
	font-size: 13px;
	word-break: break-all;
}
.section{
	margin-left: 8px;
}
.activeSection {
	padding: 5px 8px;
    border: 1px solid;
    background: #28a745;
    color: white;
    border-radius: 5px;
	cursor:  pointer;
	width: 49%;
	text-align: center;
}
.disabledSection{
	padding: 5px 8px;
    border: 1px solid;
    background: white;
    color: #28a745;
    border-radius: 5px;
	width: 49%;
	cursor: pointer;
	text-align: center;

}

.disabledSection h5{
	margin-bottom: 0px;

}
.activeSection h5{
	margin-bottom: 0px;
}

.activeSectionV2 {
	padding: 5px 8px;
    border: 1px solid;
    background: #28a745;
    color: white;
    border-radius: 5px;
	cursor:  pointer;
	width: 24%;
	text-align: center;
}
.disabledSectionV2{
	padding: 5px 8px;
    border: 1px solid;
    background: white;
    color: #28a745;
    border-radius: 5px;
	width: 24%;
	cursor: pointer;
	text-align: center;

}

.disabledSectionV2 h5{
	margin-bottom: 0px;

}
.activeSectionV2 h5{
	margin-bottom: 0px;
}
.otherSectionMain{
	border: 1px solid #ddd;
	margin-bottom: 8px;
	align-items: center;


}
.otherSection{
	padding-left: 5px;
	cursor: pointer;
	width: 15%;
	margin-bottom: 0px !important;
	padding-top: 5px;
	padding-bottom: 5px;
}
.otherSection::after{
	display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

}

@media only screen and (max-width: 600px) {
	.row {
		margin: 0px auto;
		padding: 0px !important;
	}
	
	.bottom-section {
		width: 100%;
		margin: 10px auto;
	}
	
	.customer-info {
		background-color: white;
		padding: 0px !important;
		margin-bottom: 10px;
	}
	
	.responsible {
		background-color: white;
		padding: 0px !important;
		border: 1px solid #ddd;
	}
	
	.customer-info {
		border: 1px solid #ddd;
	}
	
	.customer-info td:first-child, .responsible td:first-child {
		color: #bbb;
		font-size: 12px;
		width: 35%;
	}
	
	table {
		border-collapse: separate;
		width: 100%;
		margin: 0px auto;
	}
	
	.customer-info td, .customer-info th, .responsible td {
		padding: 7px 12px !important;
		word-break: break-all;
	}
	
	.responsible th {
		padding: 5px 20px;
	}
	
	.row table tr {
		border-bottom: 1px solid #efefef;
	}
	
	.customer-info table tr:hover, .responsible tr:not(:first-child):hover {
		background-color: #f3f3f3;
	}
	
	.customer-info tr:last-child {
		border-bottom: none;
	}
	
	.row table tr td {
		margin-left: 15px !important;
	}
	
	.topbar-section {
		width: 98%;
		padding: 10px 0px;
		margin: 10px auto;
		border: 1px solid #ddd;
		border-radius: 5px;
		background-color: white;
	}
	
	.topbar-section strong {
		font-size: 20px;
	}
	
	.topbar-left, .topbar-right {
		padding: 0px;
	}
	
	.topbar-right p {
		float: right;
		opacity: .7;
	}
	
	.topbar-right ul {
		margin: 0px;
	}
	
	.topbar-right ul li {
		float: left;
		list-style: none;
		margin: 0px 10px;
		text-decoration: none;
	}
	
	.topbar-right ul li a {
		color: grey;
		text-decoration: none;
	}
	
	.topbar-right ul li:last-child {
		margin: 0px 0px 0px 10px;
	}
	
	.topbar-right ul li .glyphicon {
		margin: 0px 5px;
		color: grey;
	}
	
	.status-green {
		color: #449D44;
	}
	
	.status-orange {
		color: #e08128;
	}
	
	.status-red {
		color: #ea3131;
	}
	
	.hide-status {
		display: none;
	}
	
	.progressbar {
		min-width: 600px;
		counter-reset: step;
	}
	
	.progressbar ul li {
		list-style-type: none;
		float: left;
		text-align: center;
		position: relative;
		width: 11.11%;
		color: grey;
		font-size: 12px;
		line-height: 14px;
		padding: 0px 3px;
		cursor: pointer;
	}
	
	.progressbar ul li.done {
		color: #bbb;
	}
	
	.progressbar ul li.active {
		color: #5fc0ea;
		font-weight: 700;
	}
	
	.progressbar ul li:before {
		content: counter(step);
		counter-increment: step;
		font-size: 22px;
		color: #bbb;
		font-weight: 700;
		width: 50px;
		height: 50px;
		line-height: 45px;
		border-radius: 50%;
		border: 3px solid #ccc;
		display: block;
		text-align: center;
		background-color: #efefef;
		margin: 5px auto;
	}
	
	.progressbar ul li.done:before {
		content: '\2713';
		color: white;
		border: 3px solid #80DBB0;
		background-color: #80DBB0;
	}
	
	.progressbar ul li.active:before {
		color: white;
		border: 3px solid #5fc0ea;
		background-color: #5fc0ea;
	}
	
	.progressbar ul li:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 4px;
		background-color: #ccc;
		top: 29px;
		left: -50%;
		z-index: -1;
	}
	
	.progressbar ul li.done:after {
		background-color: #80DBB0;
	}
	
	.progressbar ul li.active:after {
		background-color: #80DBB0;
		border: none;
		border-top: 5px dotted #5fc0ea;
		background-color: rgba(0, 0, 0, 0);
	}
	
	.progressbar ul li:first-child:after {
		content: none;
	}
	
	.hide {
		display: none
	}
	
	.table-wrapper {
		display: flex;
		padding-left: 0px !important;
		padding-right: 0px
	}
	
	.scrap-variables {
		width: 100%;
		padding: 10px 0px;
		margin: 10px auto;
		border: 1px solid #ddd;
		/* border-radius: 5px; */
		background-color: white;
	}
	
	.button-edit {
		margin-left: 40px;
		/* float: right; */
		background-color: transparent;
		border: none;
		border-radius: 3px;
		padding: 6px 10px;
		background: #bbed21;
		color: #535c69;
		-webkit-transition: background 0.5s;
		transition: background 0.5s;
	}
	
	.button-edit:hover {
		background: #d7f57e;
	}
	
	.button-cancel {
		margin-right: 40px;
		float: right;
		background-color: transparent;
		border: none;
		border-radius: 3px;
		padding: 6px 10px;
		background: white;
		color: #535c69;
		-webkit-transition: background 0.5s;
		transition: background 0.5s;
	}
	
	.button-cancel:hover {
		background: orange;
		color: #fff;
	}
	
	.button-cancel:focus, .button-cancel:active {
		outline: none;
		border: none
	}
	
	.button-edit:focus, .button-edit:active {
		outline: none !important;
		box-shadow: none;
	}
	
	.topbarVariables {
		width: 60%;
		/* padding : 2% */
	}
	
	.titleInput {
		width: 30%;
		margin-top: 10px;
	}
	
	.refreshButton {
		cursor: pointer;
		padding-left: 13px;
	}
	
	.utmVariables p {
		font-size: 13px;
		word-break: break-all;
	}
	.section{
		margin-left: 8px;
	}
	.activeSection {
		padding: 5px 8px;
		border: 1px solid;
		background: #28a745;
		color: white;
		border-radius: 5px;
		cursor:  pointer;
		width: 49%;
		text-align: center;
	}
	.disabledSection{
		padding: 5px 8px;
		border: 1px solid;
		background: white;
		color: #28a745;
		border-radius: 5px;
		width: 49%;
		cursor: pointer;
		text-align: center;
	
	}
	
	.disabledSection h5{
		margin-bottom: 0px;
	
	}
	.activeSection h5{
		margin-bottom: 0px;
	}
	
	.activeSectionV2 {
		padding: 5px 8px;
		border: 1px solid;
		background: #28a745;
		color: white;
		border-radius: 5px;
		cursor:  pointer;
		/* width: 32%; */
		text-align: center;
	}
	.disabledSectionV2{
		padding: 5px 8px;
		border: 1px solid;
		background: white;
		color: #28a745;
		border-radius: 5px;
		/* width: 32%; */
		cursor: pointer;
		text-align: center;
	
	}
	
	.disabledSectionV2 h5{
		margin-bottom: 0px;
	
	}
	.activeSectionV2 h5{
		margin-bottom: 0px;
	}
	.otherSectionMain{
		border: 1px solid #ddd;
		margin-bottom: 8px;
		align-items: center;
	
	
	}
	.otherSection{
		padding-left: 5px;
		cursor: pointer;
		width: 25%;
		margin-bottom: 0px !important;
		padding-top: 5px;
		padding-bottom: 5px;
	}
	.otherSection::after{
		display: inline-block;
		margin-left: 0.255em;
		vertical-align: 0.255em;
		content: "";
		border-top: 0.3em solid;
		border-right: 0.3em solid transparent;
		border-bottom: 0;
		border-left: 0.3em solid transparent;
	}
	.mobileRow{
		width: 100%;
		margin: 0;
		display: flex;
		justify-content: space-between;
	}
	.mobileCol{
		width: 50%;
	}
	.headText{
		margin-top: 8px;
	}
	
	.form-control{
		display: flex !important;
		flex-direction: row !important;
	}

}
