body {
  box-sizing: border-box;
}
.leadtransferTopbarSection {
	width:100%;
	padding: 10px 0px !important;
    margin: 20px auto;
    border: 1px solid gray;
    border-radius: 5px;
    background-color: white;
}
.leadtransferTopbarSection label {
    font-weight: bold;
}
.leadTransferSubmitButton {
  width : 50%;
  margin-top:30px;
}
.countSection {
  margin-top : 40px
}
.table-section {
  margin-top : 40px
}
.saview-table-section {
  margin-top : 40px
}