.procurementMainDiv {
    margin-top: 80px;
    font-size: 12px !important
}

.procurementMainDiv .tabButtons {
    margin: 10px 0 0 16px;
}

.procurementMainDiv .tabComponent {
    margin-bottom: 25px;
}

.procurementMainDiv .procurementAddVendorHeading {
    font-weight: bold;
    text-align: center;
}

.procurementMainDiv .addVendorForm {
    border: 1px solid #8ACB95;
    width: 95%;
    margin: 40px auto 0 auto;
    padding: 2% 3%;
}

.procurementMainDiv .addVendorInput {
   width: 100%;
   padding-top: 1%;
}

.vendorDetailsModal .tableDataLabel {
    font-weight: bold;
    border: 1px solid #dddddd;
    padding: 8px;
    width: 50%;
}

.vendorDetailsModal .tableDataValue {
    border: 1px solid #dddddd;
    padding: 8px;
    width: 50%
}

.vendorDetailsModal .vendorDetailsTable {
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
    border-collapse: collapse;
}

.vendorDetailsModal {
    padding: 10px;
}

.vendorDetailsModal .actionsTd {
    display: flex;
    justify-content: space-between
}