.animationload {
  background-color: #fff;
  height: 100%;
  left: 0;
  position: fixed;
  top: 40px;
  width: 100%;
}
.loading {
  animation: 1.5s linear 0s normal none infinite running loading;
  background: #fed37f none repeat scroll 0 0;
  border-radius: 50px;
  height: 50px;
  left: 50%;
  margin-left: -50px;
  margin-top: -80px;
  position: absolute;
  top: 50%;
  width: 50px;
}
.loading::after {
  animation: 1.5s linear 0s normal none infinite running loading_after;
  border-color: #85d6de transparent;
  border-radius: 80px;
  border-style: solid;
  border-width: 10px;
  content: "";
  height: 80px;
  left: -15px;
  position: absolute;
  top: -15px;
  width: 80px;
}

@keyframes loading {
  0% {
      transform: rotate(0deg);
  }
  50% {
      background: #85d6de none repeat scroll 0 0;
      transform: rotate(180deg);
  }
  100% {
      transform: rotate(360deg);
  }
}