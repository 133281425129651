.mainDiv {
    width: 100%;
    margin-top: 60px;
    padding: 2.083vw;
}

.inventoryFormDiv {
    text-align: left;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    justify-content: space-between;
}

.inventoryFormDiv div {
    /* margin-right: 1.042vw; */
    margin-bottom: 1.042vw;
}

.inventoryFormDiv div:nth-child(4n+0) {
    margin-right: 0;
}

.inventoryFormDiv label {
    width: 10.417vw;
    font-size: 0.885vw;
    font-weight: bold;
}

.inventoryFormDiv input {
    width: 20.833vw;
}

.inventoryFormDiv select {
    width: 20.833vw;
}

.headingDiv {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}