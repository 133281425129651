@media only screen and (max-width: 600px) {
    .mt-MainContainer {
        display: flex;
    }

    .createDealerContainer {
        display: flex;
        flex-direction: column;
        margin: 10px;
        margin-top: 9vh
    }

    .form-ctn {
        border: 2px solid #e6e6e6;
        margin-top: 16px;
        border-radius: 6px;
        padding: 8px 8px 8px 8px;
    }

    .getlocation-ctn{
        margin-top: 16px;
        border-radius: 6px;
        width: 100%;
    }

    .getlocation-ctn button {
        display: flex;
        background-color: #28a745;
        padding: 6px 6px 6px 6px;
        margin-bottom: 4px;
        width: 100%;
        border: none;
        color: #fff;
        border-radius: 4px;
        align-items: center;
        font-size: 18px;
        justify-content: center;
    }

    .form-select {
        width: 100%;
        margin-top: 16px;
        padding-bottom: 8px;
    }

    .formControl-select {
        width: 100%;
        color: #495057;
        background-color: #fff;
        padding: 8px 8px 8px 8px;
        border: 2px solid #e6e6e6;
        border-radius: 4px;
    }

    .form-ctn input {
        border: none;
        width: 100%;
    }

    .mtFirstContainer {
        display: flex;
        flex-direction: column;
    }

    .dlr-ctn-sec-page {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: 0;
    }

    .dlr-sec-id {
        display: flex;
        width: 80%;
        justify-content: flex-start;
    }

    .dlr-sec-id h3 {

        font-size: 22px;
        /* background-color: #6558f5; */
        color: #000;
        padding: 10px 16px 10px 12px;
        border-radius: 6px;
    }

    .dealerId-cnt {
        display: flex;
        width: 64%;
        margin-left: 10px;
        justify-content: space-between;
    }
}