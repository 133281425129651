.rt-tbody {
    text-align: center;
}

.allLeadViewFilters {
    /* height: 160px; */
    padding: 15px !important;
    /* background-color : #e9f0f2 !important */
}

.rt-th input {
    text-align: center;
}

.tableDropdownBtn {
    margin-right: 30px;
}

.tableDropdownBtn:hover {}

.tableDropdownItem {
    /* padding : 0px !important */
    padding-left: 10px;
    padding-right: 0px
}

.tableDropdownItem ::after {
    color: #fff
}

.createLeadBtn {
    background: #05c1f5;
    border: none;
    padding: 6px 10px;
    border-radius: 3px;
    -webkit-transition: background 0.5s;
    transition: background 0.5s;
    color: #fff;
    /* float: right; */
}

.createLeadBtn:hover {
    background: #4095c6
}

.moreLeads {
    background-color: transparent;
    border: 1px solid #becee8;
    border-radius: 3px;
    padding: 6px 10px;
    background: white;
    color: #535c69;
    -webkit-transition: background 0.5s;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.moreLeads:hover {
    background: #d8dde6;
}

.moreLeads:focus, .moreLeads:active {
    outline: 2px solid #becee8 !important;
    border-radius: 3px;
    /* border: 3px solid #becee8; */
    /* box-shadow: none; */
}

.createLeadBtn:active, .createLeadBtn:focus {
    outline: 2px solid #78d4eb !important;
    border-radius: 3px;
}

.savedFilterBtn {
    /* background-color: transparent; */
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-radius: 0px;
    padding: 4px 6px;
    border-right: none;
}

.savedFilterBtn :hover {
    background: #d8dde6 !important;
}

.deleteFilterBtn {
    border: 1px solid #6c757d;
    border-radius: 0px;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
    border-left: none;
    padding: 8px 12px;
    /* color : #ffffff */
    outline: 0 !important;
    box-shadow: none !important;
    width: 26px;
    height: 34px;
    text-align: center;
    padding: 0px;
    /* color : #e85d5d */
    /* background: #e85d5d;
    color: #ffffff */
}

.deleteFilterBtn :hover {
    /* color : #e85d5d; */
    color: #e85d5d;
}

.editFilterName {
    border: 1px solid #6c757d;
    border-radius: 0px;
    border-left: none;
    border-right: none;
    outline: 0 !important;
    box-shadow: none !important;
    padding: 4px 4px;
    width: 26px;
    height: 34px;
    text-align: center;
    padding: 0px;
}

.uploadBox {
    outline: 2px dashed #92b0b3;
    margin-left: 0;
    padding: 20px 10px;
}

.csvSpan {
    padding-right: 30px;
    cursor: pointer;
}

.csvSpan:hover {
    text-decoration: underline;
}

.subOrderFormTopbar {
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;
}

.prefilledSuborderButton {
    text-align: right;
    margin-top: 5vw;
    margin-right: 1.042vw;
}

.subOrderBulkEditForm * {
    margin-bottom: 18px;
}

.subOrderBulkEditForm label {
    margin: 0;
    margin-bottom: 5px;
    font-weight: bold;
}