.fieldName {
  color : #a3a9b1
}
.userImg {
  /* border-radius: 100px; */
  
}
.heading {
  font-size : 24px;
  margin-top:30px;
  margin-bottom:2px
  
}
.parentDiv{
  padding: 40px !important;
    
}
hr {
  width: 100%;
  color: black;
}
.fieldName{
  margin-bottom:0px
}
.infoDiv{
  margin-top:20px
}