@media only screen and (max-width: 600px) {

    .payment-create-form {
        display: flex;
        flex-direction: column;
        margin: 10px;
        margin-top: 8vh;
    }

    .payment-create-form h3 {
        margin-top: 16px;
        font-weight: bold;
    }

    .form-controlV2 {
        display: flex !important;
        flex-direction: column !important;
        border: none !important;
        margin-top: 10px;
    }

    .formgroupContainerPayment {
        flex-direction: row;
        margin: 14px;
        display: flex;
        align-items: center;
    }
    .formgroupContainer {
        flex-direction: row;
        margin: 14px;
        display: flex;
        align-items: center;
    }

    .formgroupContainer label {
        font-size: 16px;
        width: 50%;
        margin-left: 4px;
        color: #000;
    }

    .formgroupContainerPayment label {
        font-size: 16px;
        width: 50%;
        margin-left: 4px;
        color: #000;
    }

    .formgroupContainerPayment input {
        font-size: 16px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        width: 50%;
        margin-top: 0;
        border: 1px solid #c7c3fb !important;
    }

    .formgroupContainer input {
        font-size: 16px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        width: 58%;
        margin-top: 0;
    }

   

    .createMsg {
        display: flex;
        justify-self: center;
        align-items: center;
        flex-direction: column;
    }

    .sec-pay-edt-dealer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
    }

    .sec-pay-edt-dealer h5 {
        font-size: 18px;
    }

    .createMsg h3 {
        font-size: 22px;
        color: #495057;
    }

    .newPaymentLabel {
        font-size: 18px;
        color: #333;
        text-align: right;
        margin-right: 16px;
        float: left;
        align-items: flex-end;
        text-align: left;
    }

    .react-select-payment {
        margin-top: 1.042vw;
        margin-right: 8px;
        width: 90%
    }

    .payment-input {
        width: 15.885vw;
        padding: 0.417vw;
        margin-top: 1.042vw;
    }

    .new-payment-submit {
        align-items: center;
        display: flex;
        justify-content: flex-end;
    }

    .btnSubmit {
        width: 50%;
        background-color: #28a745;
        border: none;
        border-radius: 6px;
        color: #fff;
        font-size: 18px;
        height: 44px;
        text-align: center;
        margin-top: 2vh;
        background-color: #28a745;
        color: #FFF;
    }

    .getDesiredDateDiv {
        margin-left: auto;
        margin-right: 15px;
        display: flex;
        justify-content: space-between;
    }

    .btnDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: fit-content;
    }
}