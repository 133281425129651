.formContainer {
    border: 1px solid #8ACB95 ;
	width : 90%;
	margin : 40px auto 0 auto;
	padding: 2% 3%;
    padding-bottom: 5%;
}

.headContainer{
    width: 90%;
    padding: 10px 12px;
    margin: 10px auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
}

.headText{
    font-weight: bold;
    text-align: left;
}

.showFilter{

    display: flex;
}

.hideFilter{
    display: none
}
.moreDataContainer{
    margin: 20px 0px;
    display: flex;

}

.commentSection{
    background-color: rgb(237, 241, 243);
    margin: 15px;
    width: 60%;
    border-radius: 20px;
}
.commentedBy{
    padding: 3px;
    margin-left: 10px;
    font-size: 14px;
}
.commentTime {
    margin-left: 5px;
}
.commentedBody{
    padding: 3px;
    margin-left: 10px;
    white-space: pre-wrap;
}

.callContainer{
     display: flex;
     margin: 20px 0px;
}
.callCountContainer{
    border: 1px solid #e6e6e6;
    padding: 4px;
    align-items: center;
    display: flex;
    margin-left: 8px;
}
.callCountContainer p{
    font-size: 16px;
    margin: 0;
}
.callCountContainer h5{
    font-size: 16px;
    margin: 0;
    padding-left: 8px;
    font-weight: bold;
}
.calDistributionContainer{
    border: 1px solid #e6e6e6;
    padding: 8px;
    align-items: center;
    display: flex;
    margin-left: 1%;
    /* width: 100%; */
}
.callHeadText{
    margin: 0;
    font-size: 16px;
}

.colorMain{
    display: flex;
    justify-content: space-between;
}
.colorContainer {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    /* width: 30%; */
}
.colorContainer h6{
    font-size: 16px;
    font-weight: normal;
}