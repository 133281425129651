@media only screen and (max-width: 600px) {

    .mainContainerPayment {
        display: flex;
        margin-top: 4vh;
        flex-direction: column;
        align-items: center;
        padding: 0 8px;
    }

    .zunpulseLogo {
        width: 100%;
    }

    .label-payment-history {
        font-size: 20px;
    }

    .payment-create-btn-ctn {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .payment-create-btn-ctn button {
        background-color: #28a745;
        padding: 6px 6px 6px 6px;
        margin-bottom: 4px;
        border: none;
        color: #fff;
        border-radius: 6px;
    }

    .username {
        font-size: 20px;
        color: #fff;
        margin: 0;

    }

    .firstContainer {
        display: flex;
        margin-top: 10px;
        justify-content: space-between;
        width: 96%;
        align-items: center;
    }

    .firstContainer img {
        width: 52%;
    }

    .firstContainer h2 {
        color: #e32228;
        font-size: 26px;
    }

    .firstContainer h3 {
        color: #000;
        font-size: 22px;
        padding-top: 10px;
    }

    .secondContainer {
        flex-direction: column;
        display: flex;
        width: 96%;
    }

    .secondContainer h2 {
        color: #000;
        font-size: 22px;
        font-weight: bold;
    }

    .thirdContainer {
        display: flex;
        flex-direction: column;
        width: 96%;
        margin-top: 2vh;
    }

    .thirdContainer h2 {
        color: #000;
        font-size: 22px;
        font-weight: bold;
    }

    .fourthContainer {
        display: flex;
        flex-direction: column;
        width: 96%;
        margin-top: 10px;
    }

    .fourthContainer h2 {
        color: #000;
        font-size: 22px;
        font-weight: bold;
    }

    .dealerSearchContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        border: 2px solid #c3cfd9;
        border-radius: 2px;
    }

    .searchContainer p {
        font-size: 22px;
    }

    .dealerVisit {
        display: flex;
        flex-direction: column;
    }

    .dealerVisit p {
        font-size: 20px;
    }

    .visitDate {
        padding-top: 4px;
    }

    .visitDate p {
        font-size: 18px;
        font-weight: bold;

    }

    .left-border-ctn {
        border-left: 2px solid #000;
    }

    .summaryVisit {
        display: flex;
        justify-content: space-between;
        width: 100%;
        background-color: #ffcfcf;
        /* border: 2px solid #000; */
        border-radius: 6px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .todayVisit {
        display: flex;
        flex-direction: column;
        width: 50%;
        align-items: center;
        border-radius: 8px;
        padding-top: 10px;
    }

    .weeklyVisit {
        /* border-left: 1px solid #000; */
        height: 60%;
        display: flex;
        flex-direction: column;
        width: 50%;
        align-items: center;
        padding-top: 10px;
    }

    .todayVisit p {
        font-size: 18px;
        color: #000;
    }

    .weeklyVisit p {
        font-size: 18px;
        color: #000;
    }

    .count {
        font-size: 32px !important;
        color: #000;
        font-weight: bold;
    }

    .addNew {
        display: flex;
        width: 100%;
        justify-content: space-around;
        border: 2px solid #c3cfd9;
        border-radius: 6px;
        padding: 4px;
    }

    .paymentContainer {
        display: flex;


    }

    .addPayment {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-left: 4px;
        padding: 0px 8px;
    }

    .addPayment p {
        font-size: 18px;
    }

    .addNewHistory {
        display: flex;
        justify-content: space-between;
        width: 50%;
        padding-left: 10px;
    }

    .addNewHistory p {
        font-weight: 400;
        font-size: 20px;
    }

    .datePayment {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        /* width: 26%; */
    }

    .datePayment p {
        font-weight: bold;
        font-size: 18px;
    }

    .HistoryModalContainer {
        display: flex;
        margin-top: 4vh;
        flex-direction: column;
        align-items: center;
        border-radius: 6px;
    }

    .modalContainer {
        width: 100% !important;
    }

    .sec-pay-info {
        border-radius: 8px;
        border: 2px solid #c3cfd9;
        width: 38vh;
    }

    .sec-pay-edt {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .sec-pay-edt p {
        margin-left: 6px;
        font-size: 20px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .btn-container {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }

    .btn-container button {
        background-color: #28a745;
        padding: 6px 6px 6px 6px;
        margin-bottom: 4px;
    }

    .hr {
        margin: 0% !important;
    }

    .date-status {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 4px 0px 6px 8px;
    }

    .sts-ctn {
        display: flex;
        justify-content: space-between;
        width: 90%;
        border-radius: 8px;
        margin-top: 6px;

    }

    .sta-ctn p {
        font-size: 20px !important;
    }

    .date-con {
        margin-top: 4px;
        display: flex;
        align-items: center;
    }

    .date-con label {
        font-size: 16px;
        width: 50%;
        margin-left: 4px;
    }

    .data-con input {
        font-size: 20px !important;
    }

    .date-con input {
        font-size: 20px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        width: 50%;
        margin-top: 0;
        border: 1px solid #ced4da !important
    }

    .create-by {
        margin-top: 8px;
        display: flex;
        width: 100%;
        border: 2px solid #c7c3fb;
        text-align: left;
        border-radius: 6px;
        justify-content: space-between;
    }

    .create-by h3 {
        font-size: 20px;
        padding-left: 6px;
    }

    .create-by h5 {
        padding-right: 10px;
        font-size: 20px;
    }

   

    

  

    .ttl-fil-amt {
        margin-top: 4px;
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .ttl-fil-amt h3 {
        font-size: 20px;
        color: #000;
    }

    .dlr-his {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        width: 100%;
        border: 2px solid #c3cfd9;
        border-radius: 6px;
    }

    .row-his {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        padding: 3px 12px 4px 10px;
    }

    .row-his p {
        margin: 0;
        font-size: 16px;
    }

    .pagination {
        display: flex;
        justify-content: space-between;
        width: 40%;
    }

    .pagination p {
        font-size: 22px;
    }

    .dealerModalContainer {
        display: flex;
        flex-direction: column;
    }

    .AddDealerMsg {
        margin-left: 10px;
        display: flex;
        justify-content: flex-start;
    }

    .AddDealerMsg h3 {
        font-size: 22px;
        background-color: #6558f5;
        color: #fff;
        border-radius: 6px;
        padding: 10px 12px 10px 12px;
    }

    .sta-cit {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }

    .sta-cit .cit {
        margin-top: 4vh;
        border: 2px solid #c7c3fb;
        border-radius: 8px;
        width: 46%;
    }

    .form-group {
        margin-top: 0%;
    }

    .btn-ctn {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .btn-ctn button {
        font-size: 22px;
        background-color: #6558f5;
        color: #fff;
        border-radius: 6px;
        padding: 6px 10px 10px 10px;
        border: 1px solid #6558f5;
    }

    .dlr-sec-id {
        display: flex;
    }

    /* .dlr-ctn-sec-page {
        display: flex;
        flex-direction: column;
        width: 36vh;
    }

    .dlr-ctn-sec-page h3 {
        font-size: 22px;
        background-color: #6558f5;
        color: #fff;
        padding: 10px 12px 10px 12px;
    } */

    .dlr-idd {

        margin-top: 10px;
        font-size: 22px;
        background-color: #6558f5;
        color: #fff;
        border-radius: 6px;
        padding: 5px 0px 0px 6px;
        border: 1px solid #6558f5;
    }

    .own-img {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 2px solid #c3cfd9;
        border-radius: 6px;
    }

    .own-img img {
        width: 26px;
        margin-right: 10px;
    }

    .dealer-final-container {
        display: flex;
        flex-direction: column;
        width: 36vh;
    }

    .cmt-ctn {
        display: flex;
        flex-direction: row;
        justify-items: center;
    }

    .cmt-ctn p {
        font-size: 20px;
        background-color: #6558f5;
        color: #fff;
        border-radius: 6px;
        padding: 6px 10px 10px 10px;
        border: 1px solid #6558f5;
    }

    .cmt-box-fnl input {
        width: 90%;
        border-style: dashed;
        height: 22vh;
        border-color: #f7c325;
        background-color: #fdf3d3;
        border-radius: 8px;
    }

    .dn {
        margin-top: 16px;
        display: flex;
        justify-content: flex-end;
    }

    .dn-btn {
        font-size: 20px;
        background-color: #28a745;
        color: #fff;
        width: 50%;
        border-radius: 6px;
        padding: 6px 6px 6px 6px;
        border: 1px solid #28a745;
    }

    .filter-ctn-modal {
        display: flex;
        margin-top: 8px;
        /* border: 2px solid #c7c3fb; */
        border-radius: 6px;
        justify-content: space-between;
        width: 100%;
        flex-direction: column;
        height: 20vh;
    }

    .ftn-res-ctn {
        display: flex;
        flex-direction: row;
        border: none !important;
        align-items: center;
    }

    .ftn-res-ctn label {
        font-size: 16px;
        padding: 4px 4px 4px 4px;
        width: 50%;
    }

    .type-ctn {
        display: flex;
        justify-content: flex-start;
    }

    .searched-detailed {
        margin-top: 6px;
        display: flex;
        flex-direction: column;
        border: 2px solid #c7c3fb;
        border-radius: 6px;

    }

    .specifiec-ctn {
        display: flex;
        justify-content: space-between;
    }

    .specifiec-ctn p {
        padding-left: 6px;
        margin: 2px;
    }

    .specifiec-ctn .rightValue {
        padding-right: 10px;

    }

    .visit-view-ctn {
        padding-left: 6px;
        display: flex;
        width: 96%;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }

    .icon-name-ctn {
        display: flex;
        justify-content: space-between;
        width: 20%;
    }

    .icon-name-ctn img {
        width: 24px;
        height: 24px;
    }

    .search-pagination {
        display: flex;
        margin-top: 8px;
        justify-content: space-between;
        width: 60%;
        margin-left: 46px;
    }

    .dlr-ctn-view {
        display: flex;
        flex-direction: column;
        width: 36vh;
    }

    .dlr-container-view {
        display: flex;
        flex-direction: column;
    }

    .custm-details {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        width: 32vh;
    }

    .pen-ctn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .custm-details p {
        background-color: #6558f5;
        color: #fff;
        border-radius: 6px;
        padding: 6px 10px 10px 10px;
        align-items: center;
    }

    .shop-dtl-ctn {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .mainPaymentHistory {
        margin: 10px;
        margin-top: 8vh;

    }

    .paymentHistoryFilter {
        width: 100%;
        border: 2px solid #c3cfd9;
        margin-top: 2vw;
        padding: 8px;
    }

    .searchVisitContainer {
        overflow: scroll;
        height: 75vh;
    }

    .paginationVisit {
        display: flex;
        justify-content: space-evenly;
        padding-top: 4px;
    }

    .paginationVisit p {
        font-size: 18px;
    }

    .createDealerContainer {
        display: flex;
        flex-direction: column;
        margin: 10px;
        margin-top: 9vh;
    }

    .formContainer {
        margin-top: 16px;
        border: 2px solid #c7c3fb;
        border-radius: 8px;
    }

    .formContainer input {
        border: none;
    }

    .state-city-container {
        display: flex;
        justify-content: space-between;
    }

    .save-next-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .save-next-container button {
        font-size: 18px;
        background-color: #28a745;
        color: #fff;
        border-radius: 6px;
        margin-top: 10px;
        padding: 6px 10px 10px 10px;
        border: 1px solid #28a745;
    }


    .dealerId-container {
        display: flex;
        justify-content: space-between;
        width: 64%;
        background-color: #6558f5;
        padding: 6px 6px 2px 6px;
        border-radius: 6px;
    }

    .dealerId {
        font-size: 20px;
        color: #fff;
    }

    .dealer-final-container {
        display: flex;
        flex-direction: column;
        margin-top: 8px;
    }

    .formContainer-finalDealer {
        display: flex;
        margin-top: 20px;
        flex-direction: column;
    }

    .formgroupContainer {
        flex-direction: row;
        margin: 14px;
        display: flex;
        align-items: center;
    }

    .formgroupContainer label {
        font-size: 16px;
        width: 50%;
        margin-left: 4px;
    }


    .searhc-apply-container {
        display: flex;
        justify-content: center;
        width: 100%;

    }

    .newPaymentLabel {
        font-size: 18px;
        color: #333;
        text-align: right;
        float: left;
        align-items: flex-end;
        text-align: left;
    }
    .topHeadContainer{

        border: 1px solid #e6e6e6;
        padding: 8px 8px;
        border-radius: 8px;
    }
    .topHeadContainer h5{
        margin: 0;
    }
    

}