.generatePiMobileMainDiv .generatePiMobileSubDiv {
    margin: 60px 10px 0px 10px;
}

.generatePiMobileMainDiv .progresses {
    display: flex;
    align-items: center;
}

.generatePiMobileMainDiv .line {
    width: 40px;
    height: 6px;
    background: #63d19e;
}

.generatePiMobileMainDiv .lineWhite {
    width: 40px;
    height: 6px;
    background: #adabab;
}

.generatePiMobileMainDiv .steps {
    display: flex;
    background-color: #63d19e;
    color: #fff;
    font-size: 14px;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}


.generatePiMobileMainDiv .stepsNotDone {
    display: flex;
    background-color: #adabab;
    color: #fff;
    font-size: 14px;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.generatePiMobileMainDiv .formFieldError {
    color: red;
}

.generatePiMobileMainDiv .currentStepHeading {
    text-align: center;
    margin: 10px 0;
}

.generatePiMobileMainDiv .previousButton {
    float: left;
}

.generatePiMobileMainDiv .nextButton {
    float: right;
}

.generatePiMobileMainDiv .piGeneratedDiv {
    text-align: center;
}