
.ReactTable .rt-thead {
  background-color:#f0e9ea;
  font-weight: 550;
  font-size: 12px;
  
}
.ReactTable .rt-tbody .rt-td{
  /* border-right : 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0,0,0,0.1) ; */
  font-size : 12px;
}
.ReactTable .rt-thead.-filters {
  /* border-right: 1px solid black; */
  
}